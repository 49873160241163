<template>
  <div>
    <div class="" id="main-div" > 
      <vs-table ref="table" stripe v-model="projectSelected" @selected="handleSelected" pagination :max-items="itemsPerPage" search :data="projects" noDataText="No hay datos disponibles">

        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">      
            <!-- <vx-select style="min-width:300px" multiple v-model="filtersSelected" placeholder="Seleccione para filtrar">
              <vx-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in filterOptions"  />
            </vx-select> -->

             <v-select
              name="filter"
              label="label"
              class="vs-custom mr-4"
              style="min-width:300px"
              v-model="filtersSelected"
              :options="filterOptions"
              :reduce="label => label.id"
              :clearable="false"
              :searchable="false"
              placeholder="Seleccione para filtrar"
              multiple
            >
          </v-select>
            
          </div>

          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 items-per-page-handler">
            <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ projects.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : projects.length }} de {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="it in itemsPerPageDefinitions" @click="itemsPerPage=it" :key="it">
                <span>{{it}}</span>
              </vs-dropdown-item>            
            </vs-dropdown-menu>
          </vs-dropdown>

        </div>

        <template slot="thead">
          <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" sort>{{col.title}}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
            <vs-td>{{ tr.external_id }}</vs-td>
            <vs-td>{{ tr.id }}</vs-td>
            <vs-td>{{ tr.alias }}</vs-td>
            <vs-td>{{ tr.name }}</vs-td>
            <vs-td>{{ tr.status }}</vs-td>
            <vs-td>{{ costFormat(valOrPending(tr.cost)) }}</vs-td>
            <!-- <vs-td>            
              <vx-tooltip 
                v-if="hasAnnualInterestAlert(tr)"
                title="El valor ha cambiado"
                color="warning"
                position="left"
                :text="annualInterestAlertText(tr)"> 
                <vs-chip color="warning">
                  <vs-avatar icon-pack="feather" icon="icon-alert-triangle" style="background: none" />
                  <strong>{{ prcgFormat(valOrPending(tr.tasa), 2) }}</strong>
                </vs-chip>
              </vx-tooltip>

              <template v-if="!hasAnnualInterestAlert(tr)">
                {{ prcgFormat(valOrPending(tr.tasa), 2) }}
              </template>
            </vs-td>
            <vs-td>{{ plazoStr(tr.plazo) }} </vs-td>
            <vs-td>            
              <vx-tooltip 
                v-if="hasDepositAlert(tr)"
                title="El valor ha cambiado"
                color="warning"
                position="left"
                :text="depositAlertText(tr)"> 
                <vs-chip color="warning">
                  <vs-avatar icon-pack="feather" icon="icon-alert-triangle" style="background: none" />
                  <strong>{{ prcgFormat(valOrPending(tr.percentage), 2) }}</strong>
                </vs-chip>
              </vx-tooltip>

              <template v-if="!hasDepositAlert(tr)">
                {{ prcgFormat(valOrPending(tr.percentage), 2) }}
              </template>
            </vs-td> -->

            <vs-td>{{ agenteStr(tr.agent) }}</vs-td>
            <!-- <vs-td>
              <template v-if="indextr % 2 == 0">
                <vs-chip color="danger">KOF</vs-chip>
              </template>
              <template v-else>
                -
              </template>
            </vs-td> -->

            <vs-td class="whitespace-no-wrap">
              <vx-tooltip text="Archivar proyecto" position="left" v-if="canArchiveProject(tr)" >
                <feather-icon icon="ArchiveIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="archiveProjectRequest(tr)" />
              </vx-tooltip>
            </vs-td>

          </vs-tr>
        </template>
      </vs-table>

      <vs-prompt
        :title="archivePromptTitle"
        @cancel="closeArchivePrompt"
        @accept="doArchiveProject"
        @close="closeArchivePrompt"
        accept-text="Confirmar"
        cancel-text="Cancelar"
        :is-valid="validArchivePrompt"
        :active.sync="showArchivePrompt">
        <div class="con-exemple-prompt">
          <h6 class="mb-4">Por favor indícanos el motivo por el cual se elimina el proyecto:</h6>
          <p class="mb-2">¿Quién tomó la decisión?</p>
          <ul class="leftx mb-2">
            <li>
                <vs-radio v-model.number="actor" color="warning" vs-value="2">
                    Instalador
                </vs-radio>
            </li>
            <li>
                <vs-radio v-model.number="actor" color="warning" vs-value="3">
                    Solicitante
                </vs-radio>
            </li>
          </ul>

          <label for="" class="vs-input--label">Categoría</label>
          <v-select
            label="text"
            class="vs-custom"
            v-model.lazy="categorySelected"
            name="agent_id"
            :options="getCollection"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="true"
            placeholder="Seleccione una opción"
            @change="getCollections()">
          <div slot="no-options">No hay coincidencias</div>
          </v-select>

          <label for="" class="vs-input--label">Motivo</label>
          <v-select
            label="text"
            class="vs-custom"
            v-model.lazy="resolutionSelected"
            name="agent_id"
            :options="collections.resolutions"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="true"
            placeholder="Seleccione una opción"
          >
          <div slot="no-options">No hay coincidencias</div>
          </v-select>

          <label v-if="isOther" for="" class="vs-input--label">Otro motivo</label>
          <vs-input v-if="isOther" placeholder="Describa" class="w-full" v-model="otherReasonText" />
        </div>
      </vs-prompt>  
    </div>
    <load-error  v-if="failed"/>
  </div>

</template>

<script>
import formatHelper from '../../mixins/formatHelper';
import dateHelper from '../../mixins/dateHelper';

const columnDefinitions = [
  { title: "ID EXTERNO", key: "external_id", sortable: true },
  { title: "ID", key: "id", sortable: true },
  { title: "ALIAS", key: "alias", sortable: true },
  { title: "CLIENTE", key: "name", sortable: true },
  { title: "ESTADO", key: "status", sortable: true },
  { title: "FACTURA", key: "cost", sortable: true },
  // { title: "TASA", key: "tasa", sortable: true },
  // { title: "PLAZO (MESES)", key: "plazo", sortable: true, hideMobile: true },
  // { title: "ENGANCHE", key: "percentage", sortable: true },
  { title: "VENDEDOR", key: "agent", sortable: false, hideMobile: true },
  // { title: "PROGRAMA", key: "program", sortable: false, hideMobile: true },
  { title: "ACCIONES", key: "delete", sortable: false }
];

const filterDefinitions = [
  { id: 2, label: "En revisión" },
  { id: 3, label: "Pre aprobados" },
  { id: 4, label: "Aprobados" },
  { id: 5, label: "En fondeo" },
  { id: 6, label: "Firma de contratos" },
  { id: 7, label: "En instalación" },
  { id: 8, label: "Entregados" },
  { id: 9, label: "Rechazados" },
  { id: 10, label: "Reembolsados" },
];

const itemsPerPageDefinitions = [5,10,25,50];

const resolutionCauseDefinitions = [ 
  { id: 1, label: "El cliente optó por otro crédito" },
  { id: 2, label: "El cliente lo pagó de contado" },
  { id: 3, label: "El cliente optó por no realizar el proyecto" },
  { id: 4, label: "Otra razón" },
];

const applicantCategory = [
    { value: "4", text: "Solicitante optó por otro crédito" }
    , { value: "5", text: "Solicitante lo pagó de contado" }
    , { value: "6", text: "Solicitante canceló el proyecto" }
    , { value: "7", text: "Solicitante no cuenta con requisito" }
    , { value: "8", text: "No le gustó el financiamiento de RedGirasol" }
    , { value: "9", text: "Solicitante no cuenta con obligado solicitado" }
    , { value: "10", text: "Solicitante no cuenta con enganche solicitado" }
    , { value: "11", text: "Ya no se tiene contacto con solicitante" }
];

const supplierCategory = [
    { value: "12", text: "No le gustó el financiamiento de RedGirasol" }
    , { value: "13", text: "Faltan condiciones de instalador" }
];

const otherResolutiondIds = [4,6,14,20,24,29,39,41];

export default {
  name: "projects-list",
  props: ['status'],
  mixins: [formatHelper, dateHelper],
  components: {
  },
  async created() {
    await this.loadProjects();    
    if(this.$props.status){
      let st = parseInt(this.$props.status);
      this.filtersSelected.push(st);
    }
  },
  data() {
    return {
      projects: [],
      allProjects : [],
      orderKey: "",
      projectIdToDelete: -1,
      columns: columnDefinitions,
      filtersSelected: [],
      filterOptions: filterDefinitions,
      itemsPerPageDefinitions: itemsPerPageDefinitions,
      itemsPerPage: itemsPerPageDefinitions[1],
      isMounted: false,
      loading: false,
      failed: false,
      showArchivePrompt: false,
      resolutionCauseOptions: resolutionCauseDefinitions,
      resolutionClientCause: null,
      resolutionCause: "",
      archivingProject: null,
      projectSelected: null,
      applicantCategory: applicantCategory,
      supplierCategory: supplierCategory,
      actor: 2,
      categorySelected: null,
      resolutionSelected: null,
      collections: {
          resolutions: []
      },
      otherResolutiondIds: otherResolutiondIds,
      otherReasonText: ""
    };
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.projects.length
    },
    archivePromptTitle(){
      return "Archivar proyecto " + this.archivingProject; 
    },
    validArchivePrompt(){
      if(this.categorySelected === null || this.resolutionSelected === null){
        return false;
      }
      if(this.isOther && this.otherReasonText.length < 0){
        return false;
      }
      return true;     
    },
    getCollection(){
      if (this.actor === 2){
          return this.supplierCategory;
      } 
      return this.applicantCategory;
    },
    isOther(){
      return this.otherResolutiondIds.some(x => x === this.resolutionSelected);
    }
  },
  watch: {
    filtersSelected: function() {
      this.filterChanged();
    },
    loading: function(){
      if(this.loading) {
        this.$vs.loading();        
      }
      else {
        this.$vs.loading.close();
      }
    },
    categorySelected: function(event) {
      this.getCollections();
    }
  },
  methods: {
    async loadProjects(){
      this.isMounted = false;
      this.loading = true;
      this.failed = false;

      try {
        let response = await axios.get("/api/v1/supplier/projectsList");
        this.projects = this.allProjects = response.data;
        this.$emit("loadedProjects", this.allProjects.length);        
      } catch (e) {
        this.failed = true;
      } 
      this.isMounted = true;
      this.loading = false;
    },
    filterChanged() {
       if(this.filtersSelected.length < 1){
        this.projects = this.allProjects;
      }
      else {        
        this.projects = this.allProjects.filter(item => this.filtersSelected.includes(item.step));
      }
    },
    async handleSelected(proj){
      await this.timeout(200);
      await this.showProject(proj.id, proj.alias);
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    archiveProjectRequest (data) {
      this.archivingProject = data.id;
      let mssg = "¿Realmente desea archivar el proyecto " + data.id + "?";
      mssg += " Podrá consultarlo posteriormente en la lista de proyectos archivados."

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Archivar proyecto',
        text: mssg,
        acceptText: "Archivar",
        cancelText: 'Cancelar',
        accept: this.archiveDialog
      });
    },
    archiveDialog(){
      //limpiar opciones
      this.resolutionClientCause = null;
      this.resolutionCause = "";
      this.showArchivePrompt = true;
    },
    async doArchiveProject(){
      this.loading = true;
      let obj = {resolution_id: this.resolutionSelected, resolution_other: this.otherReasonText };

      try {
        let res = await axios.post(`/api/project/${this.archivingProject}/archive`, obj);
        this.$router.go();

      } catch (error) {
        this.$vs.notify({
         color: 'danger',
         title: 'Error',
         text: 'Ha ocurrido un error en la operación, intente más tarde'
       })
      }
      this.loading = false;
    },
    closeArchivePrompt() {
      this.resolutionSelected = null;
      this.categorySelected = null;
      this.otherReasonText = "";
      this.collections.resolutions = [];
      this.showArchivePrompt = false;
    },
    async showProject(id, alias){
      await this.$router.push({ name: 'detalleProyecto', params: { id: id, alias: alias } })
    },
    plazoStr(value) {
      if (value == null) return "";
      else if (value == "Pendiente") return value;
      else if (value == 0) return "Pendiente";
      else return value;
    },
    valOrPending(value){
      return value == 0 ? 'Pendiente' : value;
    },
    agenteStr(value) {
      if (value !== false) return value.name;
      else return "N/A";
    },
    canArchiveProject(project) {
      return project.step < 5;
    },
    hasAnnualInterestAlert(proj){
      return (proj.annual_interest_percentage_update != null
       && proj.annual_interest_percentage_update_supplier_confirmation == null);
    },
    annualInterestAlertText(proj){
      return "El valor de la tasa de interés fue actualizado el " + this.midDateFormat(proj.annual_interest_percentage_update) 
      + ". Valor previo: " + this.prcgFormat(proj.annual_interest_percentage_update_previous, 2);
    },
    hasDepositAlert(proj){
      return (proj.deposit_update != null
       && proj.deposit_update_supplier_confirmation == null);
    },
    depositAlertText(proj){
      return "El valor del porcentaje de interés fue actualizado el " + this.midDateFormat(proj.deposit_update) 
      + ". Valor previo: " + this.prcgFormat(proj.deposit_previous);
    },
    async getCollections(){
      try {
          const res = await axios.get(`/api/v1/forms/getResolutionByCategory/${this.categorySelected}`);
          let data = res.data;
          this.collections.resolutions = [];
          this.resolutionSelected = null;

          data.forEach(element => {
              let obj = {
                  value: element.id,
                  text: element.description
              }
              this.collections.resolutions.push(obj);
          });
          
      }
      catch (e) {
          this.warn(e);
      }
    },
  },
};
</script>

